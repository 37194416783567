import { Component as ChatIcon } from 'icons/icon-ui--chat.svg';
import { useEffect, useState } from 'react';

function ChatButton() {
  const [isVisible, setIsVisible] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showClose, setShowClose] = useState(window.innerWidth < 1025);
  const isOnSignUp = window.location.pathname.startsWith('/sign_up');
  const originalPushState = window.history.pushState;

  // Here we are overriding the default pushState method of the History API because
  // the Chat widget is injected into the page via ERB and not a component that's within
  // the SPA's component tree. Since they are two separate, unrelated components, we had
  // no good way to trigger functionality in one from the other. Alternative solution is
  // using navigation event listener once it is supported on firefox & safari
  window.history.pushState = (state, title, url) => {
    originalPushState.call(window.history, state, title, url);
    setIsVisible(true);
    if (window.innerWidth > 1025) setShowClose(false);
  };

  useEffect(() => {
    const handleResize = () => {
      const currentWidth = window.innerWidth;
      setWindowWidth(currentWidth);
      setShowClose(currentWidth < 1025);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMouseEnter = () => {
    if (windowWidth > 1025) {
      setShowClose(true);
    }
  };

  const handleMouseLeave = () => {
    if (windowWidth > 1025) {
      setShowClose(false);
    }
  };

  const handlToggleAda = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    window.adaEmbed.toggle();
  };

  if (isVisible) {
    return (
      <div
        style={{
          bottom: '15px',
          padding: '15px',
          textAlign: 'right',
          pointerEvents: 'none',
          marginBottom: windowWidth < 1025 && isOnSignUp ? '3rem' : '0',
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <button
          id="chat-button"
          onClick={handlToggleAda}
          type="button"
          aria-label="Close modal"
          style={{
            padding: '1rem',
            borderRadius: '50%',
            border: 'none',
            position: 'relative',
            zIndex: 1,
          }}
        >
          <ChatIcon />
        </button>
        { showClose && (
        <button
          onClick={() => setIsVisible(false)}
          type="button"
          aria-label="Close chat"
          style={{
            position: 'absolute',
            top: '22px',
            right: '27px',
            width: '24px',
            height: '24px',
            background: 'white',
            color: 'black',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            border: '2px solid grey',
            padding: '0',
            zIndex: 2,
          }}
        >
          &times;
        </button>
        )}
      </div>
    );
  }
  return null;
}

export default ChatButton;
